import React from "react"
import { FaMapMarkerAlt } from "react-icons/fa"
import "../../styles/style.css"
import Mapa from "../../../static/images/cloud/spain.svg"

const Location = () => {
  return (
    <div className="location-section">
        <div className="location-text">
            <p>Puedes disponer de nuestros servicios desde cualquier punto de España.</p>
            <div className="location-city">
                <div className="city">
                    <a href="/cloud-infraestructura/soporte-informatico/madrid/"><p><FaMapMarkerAlt size={13}/>Madrid</p></a>
                    <a href="/cloud-infraestructura/soporte-informatico/barcelona/"><p><FaMapMarkerAlt size={13}/>Barcelona</p></a>
                    <a href="/cloud-infraestructura/soporte-informatico/valencia/"><p><FaMapMarkerAlt size={13}/>Valencia</p></a>
                </div>
            </div>
        </div>
        <img src={Mapa} alt="Mapa de España"/>
    </div>
  )
}

export default Location